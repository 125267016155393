import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n'
import http from "./assets/http";
import $ from 'jquery';  //必须引入jquery
import 'jquery-ui-dist/jquery-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/reset.css'
import './assets/css/examination.less'
import './assets/css/login.less'
import './quasar'
import moment from 'moment';
moment.locale('zh-cn');
Vue.filter('dateformat', (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') =>{
  return moment(dataStr).format(pattern)
})
Vue.use(VueI18n)

Vue.use(ElementUI);

Vue.prototype.$http = http
Vue.config.productionTip = false
Vue.prototype.$ = $
Vue.prototype.$moment = moment
Vue.prototype.replaceImg = function  replaceImg(a){
  if (a){
    // const reg = /<a[^>]*href=[ '"]([^"]*)[' "][^>]*>(.*?)<\/a>/g;
    var abianqian = a.match(/___/g)
    // var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;// img 标签取src里面内容的正则
    // var s = a.match(b);// 取到所有img标签 放到数组 s里面
    if (abianqian){
      abianqian.forEach((item,index)=>{
        a = a.replace(new RegExp(item, 'g'), '<input type="text" name="'+(index+1)+'">'); //'http://www.dlzjzx.tj.cn'自己替换的内容
      })
    }
    return a
  }
}
Vue.prototype.replaceP = function  replaceP(html){
  html = html.replace(/<\/?p[^>]*>/gi,'')
  return html
  // console.log(html)
  // if (a){
  //   var abianqian = a.match(/___/g)
  //   if (abianqian){
  //     abianqian.forEach((item,index)=>{
  //       a = a.replace(new RegExp(item, 'g'), '<input type="text" name="'+(index+1)+'">'); //'http://www.dlzjzx.tj.cn'自己替换的内容
  //     })
  //   }
  //   return a
  // }
}
// 获取本地存储的数据
Vue.prototype.getItem = function  getItem(name){
  return localStorage.getItem(name)
}
// 获取当前时间
Vue.prototype.getTime = function  getTime(){
  return moment(new Date().getTime()).format('YYYY-MM-DD');

}
router.beforeEach((to, from, next) => {
  // 1.如果访问的是登录页面（无需权限），直接放行
  if (to.path === '/login') return next()
  // 2.如果访问的是有登录权限的页面，先要获取token
  const tokenStr = localStorage.getItem('token')
  // 2.1如果token为空，强制跳转到登录页面；否则，直接放行
  if (!tokenStr) return next('/login')
  next()
})
const i18n = new VueI18n({
  locale: 'zh', // 语言标识
  messages: {
    'zh': require('./assets/lang/zh'),
    'en': require('./assets/lang/en')
  }
})
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

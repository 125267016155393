import axios from 'axios'
// const URL = 'http://192.168.0.187/qinbang/public/index.php/api/'
const URL = 'https://www.qbmusic.net/api/'  // 'http://www.qbmusic.net/api/'
import router from '../router/'
export default {
  post (url, params) {
    let token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      axios.post(URL + url, params, {
        headers: {
          'Content-type': 'application/json',
          'token': token
        }
      }).then(res => {
        console.log(URL + url, params)
        if (res.data.code === 200) {
          resolve(res.data)
        } else if (res.data.code === 401){
          localStorage.removeItem('token')
          router.push({path: '/login'})
        } else if (res.data.code == undefined){
          localStorage.removeItem('token')
          router.push({path: '/login'})
        }else{
          reject(res.data)
        }
      }).catch(err =>{
        console.log(err)
        router.push({path: '/login'})
      })
    })
  },
}
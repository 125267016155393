module.exports = {
    home : {
        gw:'Official website',
        homes:"HOME",
        start:'start',// 开始
        SwitchLanguage:'SwitchLanguage',
        SignUpNow:'Sign up now!',
        Trial:'Trial',// 试用
        Questions:'Questions', //题
        Minutes:'Minutes',// 分钟
        Points:'Points',// 分
        Next:'Next', //下一题
        Prev:'Prev',// 上一题
        EndTest:'End Test', //结束考试
        Clear:'Clear',//清除
        Mark:'Mark',// 标记
        Prompt:'Prompt', //提示
        AreYouSureToEndTest:'Are you sure to end test?',//确定结束考试吗?
        Cancel:'Cancel',//取消
        End:'End Test', //结束
        Answer:'Answer',// 回答
        ViewDetail:'View Detail',//查看试卷
        ScoreAnalysis:'Score Analysis',// 成绩分析
        Candidate:'Candidate', //考生
        KnowledgePoint:'Knowledge Point',//知识点
        NumberOfQuestions:'Number Of Questions',//题目数量
        Date:'Date',//日期
        Duration:'Duration',// 耗时
        ScoreTotal:'Score/Total',// 得分/总分
        CorrectQuestionsTotalQuestions:'Correct Questions/Total Questions',//得分/总分
        ResultByCategoryScoreTotal:'Result By Category（Score/Total）',// 正确题数/总题数
        Rhythm:'Rhythm',//节奏
        Pitch:'Pitch', //音高
        KeyAndScale:'Key And Scale',// 调与音阶
        Intervals:'Intervals',// 音程
        Chords:'Chords',// 和弦
        TermsSignsAndInstruments:'Terms, Signs And Instruments',// 音乐术语，标记与乐器
        MusicInContext:'Music In Context', //乐谱分析
        ExerciseHistory:'Exercise History\n', //练习记录
        ExamName:' Exam Name ', //试卷名称
        YourScore:' Your Score ',//你的得分
        DurationMinute:' Duration（Minute） ',//答题耗时(分钟)
        StartTime:' Start Time ',//开始时间
        Result:'Result ',// 操作
        Detail:'Detail',//详情
        RecordsPerPage:'Records per page', // 每页记录数：
        TotalWrongAnswers:'Total Wrong Answers', // 总错题数
        ResultByCategory:'Result By Category',// 错题分类统计
        NumberOfWrongAnswers :'Number Of Wrong Answers',// 错题数
        Wronganswerssummarybyknowledgepoint:'Wrong answers summary by knowledge point',
        Notice:'Notice',// 通知
        Profile:'Profile',
        DaysToExpiration:'Days To Expiration',
        FollowTeacher:'Follow Teacher',
        UserManual:'User Manual',
        LogOut:'Log Out',
        jinyong:'Disable',
        AddStudents:'Add Students',
        OK:'Add Students',
        StudentsAccount:'Students Account',
        EnterStudentsAccount:'Enter Students Account',
        Notices:'Notice',
        PermissionTimeHasExpired:'Your permission has expired. The test can only be saved for 30 days. If you cancel renewal,the system will delete the test automatically after 30 days.',
        Renewal:'Renewal',
        TeacherExpiration:'The permission of the teacher\'s account has expired, please renew the account in time.',
        CreateExam:'Create Exam',
        TimeLimit:'Time Limit',
        STARTs:'START',
        BuyNow:'Buy Now',
        YouHaveBeenSelected:'You have been selected',
        questions:'questions',
        GeneratePaper:'Generate Paper',
        user:'User',
        ChangePassword:'Change Password',
        student:'Student',
        teacher:'Teacher',
        NumberOfQuestionsS:'Number Of Questions',
        determine:'Determine',
        EnterExamName:'Enter Exam Name',
        EnterTimeLimit:'Enter Time Limit',
        EnterNumberOfQuestions:'Enter Number Of Questions',
        StudentList:'Student List',
        Bound:'Bound',
        Return:'Return',
        CorrectAnswer:'Correct Answer',
        Explain:'Explain',
        CheckTheOriginalQuestion:'Check The Original Question',
        PleaseInputSearchCriteria:'Please input search criteria',
        loading:'Loading',
        SignInSuccessfully:'Sign in successfully',
        Areyousureyouwanttodeletethistestpaper:'Are you sure you want to delete this test paper',
        Deletesucceeded:'Delete succeeded',
        PleaseSelectATopicFirst:'Please select a topic first',
        TheCurrentlySelectedTopicHasExceeded:'The currently selected topic has exceeded',
        individual:'individual',
        Pleasereducebeforegenerating:'Please reduce before generating',
        Enable:'Enable',
        ManagingStudents:'Managing Students',
        ModifyAvatar:'Modify Avatar',
        ConfirmBinding:'Confirm Binding',
        Grade:'Grade',
        PleaseSelectYourLevel:'Please select your level'
    },
    login:{
        SignInByPassword:'Sign in by password',// 密码登入
        SignInByPhone:'Sign in by phone',//手机登入
        SignInByWechat:'Sign in by Wechat', // 微信扫码登入+
        AccountNumberorPhone:'Account number or phone',//账号
        EnterAccountNumberorPhone:'Enter Account Numberor Phone',
        Password:'Password',//密码
        LogIn:'Log In',//登入
        CreateAccount:'Create account',//用户注册
        ForgotEmail:'Forgot email',// 忘记密码
        PhoneNumber:'Phone Number',//手机
        Code:'Code',//验证码
        GetCode:'Get Code',//获取验证码
        PleaseScanTheCodeLogIntoMusicalTheorySystem:'Please scan the code，log into musical theory system',// 手机扫码，安全登入
        ScanSuccessful:'Scan Successful',// 扫描成功
        WrongCredentials:'Wrong Credentials',//登入错误
        InvalidUsernameOrPassword:'Invalid username or password',// 用户名或密码无效
    },
    register:{
        CreateNewAccount:'Create New Account',//用户注册
        YourIdentity:'Your Identity',//身份
        AccountNumber:'Account number',// 账号
        Password:'Password',// 密码
        EnterYourPassword:'Enter Your Password',//请输入您的密码
        ConfirmYourPassword:'Confirm Your Password',//确认密码
        EnterYourPasswordAgain:'Enter Your Password Again',//请再次输入您的密码
        SignUp:'Sign Up',//注册
        YouWillGetFreeTrialOfMusicalTheoryAfterSignUp:'You will get free trial of musical theory after Sign Up.',// 注册后即可免费试用乐理系统
        PhoneNumber:'Phone Number',//手机号
        SignUpByPhone:'Sign up by phone',// 手机注册
        ChangingYourPassword:'Changing Your Password',// 手机验证改为修改密码
    }
}
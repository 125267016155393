<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'LayoutDefault',

  components: {

  },
  created() {
    if (localStorage.getItem('locale')){
      this.$i18n.locale = localStorage.getItem('locale')
      this.$store.state.paperLanguage = localStorage.getItem('locale')
      let lang = ''
      if (this.$i18n.locale == 'zh'){
        lang = 'zh-cn'
      }else {
        lang = 'en'
      }
      this.$http.post('index/lang',{
        lang:lang
      }).then(res =>{
        console.log(res)
      }).catch(err =>{
        console.log(err)
      })
    }
  },
  watch:{
    $route(){
      this.$store.state.active = 0
    }
  },
  data () {
    return {
      leftDrawerOpen: false
    }
  }
}
</script>

<style>
body,html{
  width: 100%;
  height: 100%;
}
#app{
  width: 100%;
  height: 100%;
}
@media screen and  (min-width:0px) and (max-width: 900px) {
  .q-menu{
    max-width: 340px !important;
  }
  .user_info[data-v-fae5bece] {
    width: auto;
    height: auto !important;
  }
  .user_info_bot>span{
    text-align: center;
    display: block;
  }
  .pop-box {
    padding: 10px 15px !important;
  }
  .pop-title {
    font-size: 22px !important;
  }
  .pop-box .input-lable {
    margin-left: 20px !important;
  }
}
</style>

module.exports = {
    home : {
        gw:'官方网站',
        homes:"首页",
        start:'开始',// 开始
        SwitchLanguage:'切换语言', // 切换语言
        SignUpNow:'立即注册', //立即注册
        Trial:'试用', // 试用
        Questions:'题', //题
        Minutes:'分钟',// 分钟
        Points:'分',// 分
        Next:'下一题', //下一题
        Prev:'上一题',// 上一题
        EndTest:'结束考试', //结束考试
        Clear:'清除',//清除
        Mark:'标记',//标记
        Prompt:'提示', //提示
        AreYouSureToEndTest:'是否结束考试?',//确定结束考试吗?
        Cancel:'取消',//取消
        End:'结束', //结束
        Answer:'回 答',// 回答
        ViewDetail:'查看试卷',//查看试卷
        ScoreAnalysis:'成绩分析',// 成绩分析
        Candidate:'考生', //考生
        KnowledgePoint:'知识点', //知识点
        NumberOfQuestions:'题目数量', //题目数量
        Date:'日期',//日期
        Duration:'耗时',// 耗时
        ScoreTotal:'得分/总分',// 得分/总分
        CorrectQuestionsTotalQuestions:'正确题数/总题数',//得分/总分
        ResultByCategoryScoreTotal:'考点分类统计',// 正确题数/总题数
        Rhythm:'节奏', //节奏
        Pitch:'音高', //音高
        KeyAndScale:'调与音阶',// 调与音阶
        Intervals:'音程',// 音程
        Chords:'和弦',// 和弦
        TermsSignsAndInstruments:'音乐术语，标记与乐器',// 音乐术语，标记与乐器
        MusicInContext:'乐谱分析', //乐谱分析
        ExerciseHistory:'练习记录', //练习记录
        ExamName:'试卷名称', //试卷名称
        YourScore:'你的得分',//你的得分
        DurationMinute:'答题耗时(分钟)',//答题耗时(分钟)
        StartTime:'开始时间',//开始时间
        Result:'操作 ',// 操作
        Detail:'详情',//详情
        RecordsPerPage:'每页记录数：',// 每页记录数：
        TotalWrongAnswers:'总错题数',
        ResultByCategory:'错题分类统计',// 错题分类统计
        NumberOfWrongAnswers :'错题数',// 错题数
        Wronganswerssummarybyknowledgepoint:'错题知识点统计',
        Notice:'通知',
        Profile:'用户名',
        DaysToExpiration:'有效期',
        FollowTeacher:'绑定老师',
        UserManual:'使用说明',
        LogOut:'登出',
        jinyong:'禁用',
        AddStudents:'添加学生',
        OK:'绑定学生',
        StudentsAccount:'学生账号',
        EnterStudentsAccount:'请输入学生账号',
        Notices:'温馨提示',
        PermissionTimeHasExpired:'权限时间已到期，试卷仅保存30天。如无续费，系统将自动删除原先保存的试卷。',
        Renewal:'续费',
        TeacherExpiration:'所属教师账号权限已到期，请及时续费，以免影响使用。',
        CreateExam:'组 卷',
        TimeLimit:'答题时长',
        STARTs:'开始组题',
        BuyNow:'购买试卷',
        YouHaveBeenSelected:'已选择',
        questions:'题目',
        GeneratePaper:'生成试卷',
        user:'用户信息',
        ChangePassword:'修改密码',
        student:'学生',
        teacher:'老师',
        NumberOfQuestionsS:'题数',
        determine:'确定',
        EnterExamName:'请输入试卷名称',
        EnterTimeLimit:'请输入答题时长',
        EnterNumberOfQuestions:'请输入题数',
        StudentList:'学生列表',
        Bound:'已绑定',
        Return:'返回',
        CorrectAnswer:'正确答案',
        Explain:'解释',
        CheckTheOriginalQuestion:'查看原题',
        PleaseInputSearchCriteria:'请输入搜索条件',
        loading:'跳转中',
        SignInSuccessfully:'登录成功',
        Areyousureyouwanttodeletethistestpaper:'确定要删除此试卷吗',
        Deletesucceeded:'删除成功',
        PleaseSelectATopicFirst:'请先选择题目',
        TheCurrentlySelectedTopicHasExceeded:'当前选择题目已超出',
        individual:'个',
        Pleasereducebeforegenerating:'请减少后再进行生成',
        Enable:'启用',
        ManagingStudents:'管理学生',
        ModifyAvatar:'修改头像',
        ConfirmBinding:'确定绑定',
        Grade:'等级',
        PleaseSelectYourLevel:'请选择您的等级'
    },
    login:{
        SignInByPassword:'密码登入',// 密码登入
        SignInByPhone:'手机登入',//手机登入
        SignInByWechat:'微信扫码登入', // 微信扫码登入+
        AccountNumberorPhone:'账号',//账号
        EnterAccountNumberorPhone:'请输入账号',
        Password:'密码',//密码
        LogIn:'登入',//登入
        CreateAccount:'用户注册',//用户注册
        ForgotEmail:'忘记密码\n',// 忘记密码
        PhoneNumber:'手机',//手机
        Code:'验证码',//验证码
        GetCode:'获取验证码',//获取验证码
        PleaseScanTheCodeLogIntoMusicalTheorySystem:'手机扫码，安全登入',// 手机扫码，安全登入
        ScanSuccessful:'扫描成功',// 扫描成功
        WrongCredentials:'登入错误',//登入错误
        InvalidUsernameOrPassword:'用户名或密码无效',// 用户名或密码无效
    },
    register:{
        CreateNewAccount:'用户注册',//用户注册
        YourIdentity:'身份',//身份
        AccountNumber:'账号',// 账号
        Password:'密码',// 密码
        EnterYourPassword:'请输入您的密码',//请输入您的密码
        ConfirmYourPassword:'确认密码',//确认密码
        EnterYourPasswordAgain:'请再次输入您的密码',//请再次输入您的密码
        SignUp:'注册',//注册
        YouWillGetFreeTrialOfMusicalTheoryAfterSignUp:'注册后即可免费试用乐理系统',// 注册后即可免费试用乐理系统
        PhoneNumber:'手机号',//手机号
        SignUpByPhone:'手机注册',// 手机注册
        ChangingYourPassword:'修改密码',// 手机验证改为修改密码
    },
    GroupQuestions:{
        HomeworkFromTeacher:'教师组题',// 教师组题
        TeachersCreateCustomExamForStudentsAccordingToTheirlearningsituation:'教师根据学生的学习情况，为学生创建有针对性的考试。',// 教师根据学生的学习情况，为学生创建有针对性的考试。
        trial:'试用',// 试用
        Notice:'通知',// 通知
        TestCreationInMusicialKnowledge:'知识点组题',//知识点组题
        AccordingToThePointOfTheMusicialTheoryAIRandomlyCreateCustomExamForSpecialTraining:'根据题目的知识点分类，AI随机生成试卷，用于考生的专项训练',// 根据题目的知识点分类，AI随机生成试卷，用于考生的专项训练
        AIRandomlyGeneratesSpecialTrainingPapersAccordingToTheKnowledgeBlocksOfTheABRSMExamMusicTheory:'根据英皇考试乐理的知识点板块，AI随机生成专项训练试卷',// 根据英皇考试乐理的知识点板块，AI随机生成专项训练试卷

    }
}
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    editPwdType:false,
    teacherType:false,
    studentType:false,
    studentListType:false,
    userInfo:[],
    buy:false,
    groupType:false,
    active:0, //当前分页
    // imgHttp:'http://192.168.0.187/qinbang/public/',
    imgHttp:'http://www.qbmusic.net/',
    paperLanguage:'zh',
    workTime:0 ,//做题耗时
    ListByExamId:[], //所有题目集合
    xuanzeTiMuList:[], // 分类总选择题数量
    xuanzeTiMuListZhi:[], // 知识点总选择数量
    teacherClassList:[], // 分类列表选择数量
    zhishiDianList:[], // 知识点列表选择数量
    pay:false, // 购买弹窗
    topQueDing:false,
    pageList:[],
    qusData:[], // 错题本问题
    qusType: 1, // 错题本问题类型
    butType:1, // 購買類型 1 試卷 2老師
  },
  getters:{
		
	},
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

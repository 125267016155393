import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/',
    name: 'Home',
    meta: { title: '首页',titleEn:'HOME' },
    component: () => import('../views/Home.vue'),
    children:[
      // {
      //   path: '/Home',
      //   name:'home',
      //   component: () => import ('../views/Home/index'),
      // },
      {
        path: '/choose-exam',
        name: 'choose-exam',
        meta: { title: '模拟试卷',titleEn:'Mock Exam' },
        component: () => import('../views/Home/ChooseExam.vue')
      },
      {
        path: '/practiceNotes',
        name: 'practiceNotes',
        meta: { title: '练习记录',titleEn:'Exercise History' },
        component: () => import('../views/practiceNotes.vue'),
        children: [{
          path: '/examResult',
          name: 'examResult',
          meta: {title: '练习结果总览',titleEn:'Result Summary'},
          component: () => import('../views/examResult.vue')
        },]
      },
      {
        path: '/notice',
        name: 'notice',
        meta: { title: '通知',titleEn:'Notice' },
        component: () => import('../views/Notice.vue')
      },{
        path: '/groupQuestions',
        name: 'groupQuestions',
        component: () => import('../views/GroupQuestions.vue')
      },{
        path: '/groupQuestionsList',
        name: 'groupQuestionsList',
        meta: { title: 'AI智能组题',titleEn:'AI Exam Generator' , type:'exam_ai'},
        component: () => import('../views/ManualList.vue'),
        children:[{
          path: '/choiceType',
          name: 'choiceType',
          meta: { title: '选择分类',titleEn:'Select Classification' },
          component: () => import('../views/choiceType.vue'),
        },{
          path: '/manualModular',
          name: 'manualModular',
          component: () => import('../views/ManualModular.vue'),
        }]
      },{
        path: '/wrongBook',
        name: 'wrongBook',
        meta: { title: '错题本', titleEn:'Mistake Notebook'},
        component: () => import('../views/WrongBook.vue'),
        children: [{
          path: '/wrongQuestion',
          name: 'wrongQuestion',
          meta: { title: '错题', titleEn:'Wrong Question'},
          component: () => import('../views/wrongQuestion.vue'),
        }]
      },
      {
        path: '/teacherGroupQuestions',
        name: 'teacherGroupQuestions',
        meta: { title: '老师组题' , titleEn:'Homework From Teacher', type:'exam_teacher'},
        component: () => import('../views/ManualList.vue'),
        children: [{
          path: '/teacherType',
          meta: { title: '选择类型',titleEn:'Select Type' },
          component: () => import('../views/teacherType.vue'),
          children:[{
            path: '/manualList',
            name: 'manualList',
            meta: { title: '考点分类组题',titleEn:'Classification Group Questions' },
            component: () => import('../views/ManualList.vue'),
          },{
            path: '/teacherManualModular',
            name: 'manualModular',
            meta: { title: '知识点',titleEn:'Knowledge Points Group Questions' },
            component: () => import('../views/ManualModular.vue'),
            children:[
              {
                path: '/questionBank',
                name: 'questionBank',
                meta: { title: '选择问题',titleEn:'Selection Questions' },
                component: () => import('../views/QuestionBank.vue'),
              }
            ]
          }]    
        }]
      }
    ]
  },{
    path: '/exam',
    name: 'exam',
    component: () => import('../views/Exam.vue')
  },
  {
    path: '/resultQuestionDetails',
    name: 'resultQuestionDetails',
    meta: { type:'result' },
    component: () => import('../views/Exam.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
